<template>
  <!--
    The view for the InstallationEventList-component.
    Displays all installation events.
  -->
  <Portlet
    title="Event"
    class="events"
    icon="calendar-plus"
  >
    <template slot="buttons">
      <toggle-button
        id="viewToggle"
        v-model="past"
        :labels="{ checked: 'Present', unchecked: 'Past' }"
        :color="{ checked: 'gray', unchecked: 'green' }"
        :width="80"
        :height="30"
        :font-size="10"
        class="m-0 mr-2"
        @change="changePast()"
      />

      <button
        v-if="
          authenticationHasRole('crud_events') ||
            (authenticationHasRole('scope_staff') &&
            (authenticationHasRole('secondlevel') ||
            authenticationHasRole('admin') ||
            authenticationHasRole('firstlevel')))
        "
        class="btn btn-sm btn-primary float-right mt-2 mb-2"
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t("addEvent") }}
        </span>
      </button>
    </template>
    <LoadingPlaceholder v-if="loading" />
    <InstallationEventList
      v-else
      ref="list"
      :installation-id="installationId"
      :service-id="serviceId"
      :past="past"
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :installation-id="installationId"
      :is-installation-events="true"
      @reload="reload"
    />
  </Portlet>
</template>

<script>
import { authenticationMixin } from "@/mixins/authenticationMixin";

export default {
  name: "InstallationEvent",
  components: {
    InstallationEventList: () =>
      import("@/components/Installation/InstallationEventList.vue"),
    DatabaseAuditLogList: () =>
      import("@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue"),
  },
  mixins: [authenticationMixin],
  props: {
    installationId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      past: false,
      loading: false,
      serviceId: null,
    };
  },
  created () {
    this.getInstallation();
  },
  methods: {
    showSidebarAdd () {
      this.$refs.list.addEvent();
    },
    reload () {
      this.$refs.list.getInstallationEvents();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    changePast () {
      this.past = !!this.past;
    },
    getInstallation () {
      this.loading = true;
      this.axios
        .get("/Installation/Get?id=" + this.installationId)
        .then((response) => {
          let installation = response.data;
          this.serviceId = installation.statusServiceId;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
